import React, { useState, useEffect } from "react";
import { TextField, Grid, Modal, Box, Typography, makeStyles } from "@material-ui/core";
import './OfficeDropdown.css';

const useStyles = makeStyles((theme) => ({
  outlinedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000000",
      },
      "&:hover fieldset": {
        borderColor: "#000000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000000",
      },
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
    "& .MuiInputLabel-root": {
      color: "#000000",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    maxHeight: "80vh",
    overflowY: "auto",
  },
  officeListItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #ddd",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
}));

function OfficeDropdown({setOffice, office}) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState(office || "");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [officeLocations, setOfficeLocations] = useState([]); // Holds locations from JSON file

  // Fetch the pre-converted JSON file on component mount
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/offices.json"); // Fetching pre-converted JSON
      const data = await response.json();
      setOfficeLocations(data.Sheet1);
    };

    fetchData(); // Fetch the JSON file when the component mounts
  }, []);

  // Function to handle the search input
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredLocations([]); // Hide the dropdown if the input is empty
    } else {
      const filtered = officeLocations
      .filter(location => location !== null)  // Ignore null values
      .filter(location => {
        // Ensure properties exist before accessing them to avoid errors
        return (
          location.Column2 && location.Column2.toLowerCase().includes(value.toLowerCase()) ||
          location.Column6 && location.Column6.toLowerCase().includes(value.toLowerCase()) ||
          location.Column5 && location.Column5.includes(value)
        );
      });
        setFilteredLocations(filtered.slice(0, 100)); // Limit the dropdown to 5 results
    }
  };

  // Function to handle the office selection
  const handleOptionClick = (office) => {
    setSearchTerm(`${office.Column2} - ${office.Column6}`);
    setOffice(office)
    setFilteredLocations([]); // Hide the dropdown after selection
  };

  return (
    <div className="office-search-container">
      <Grid item xs={12}>
        <TextField
          label="Търсене на офис"
          variant="outlined"
          className={classes.outlinedInput}
          value={searchTerm}
          onChange={handleSearch}
          fullWidth
        />
      </Grid>
      {filteredLocations.length > 0 && (
        <ul className="office-list">
          {filteredLocations.map((location) => (
            <li
              key={location.id}
              className="office-item"
              onClick={() => handleOptionClick(location)}
            >
              <div className="office-icon">📍</div>
              <div className="office-details">
                <strong>{location.Column2}</strong> - {location.Column6}
              </div>
            </li>
          ))}
        </ul>
      )}
 
    </div>
  );
}

export default OfficeDropdown;
