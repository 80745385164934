import React, { useEffect, useState } from "react";
import "./Home.css";
import HomeCard from "./HomeCard";
import MataData from "../layouts/MataData/MataData";
import { clearErrors, getLatestProducts, getProduct, getShuffledProducts } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import HeroSlider from "./HeroSilder";
import Banner from "./BannerStack/Banner";
import ProductGrid from "./BannerStack/ProductGrid";
import { mainBanner, shuffleArray, sliderData } from "../../constants/homeConstants";
import SliderComponent from "./BannerStack/SliderComponent";
import { Link } from 'react-router-dom/cjs/react-router-dom';

function Home() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, latestProducts, shuffledProducts } = useSelector((state) => state.products);

  // Fetch latest and shuffled products on mount
  useEffect(() => {
    dispatch(getLatestProducts());
    dispatch(getShuffledProducts());
  }, [dispatch]);

  // Error handling
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MataData title="Sneaker Brigade" />
          <div className="Home_Page">
            <HeroSlider />
            <h1>Нови чифтове</h1> 
            <div className="newArrivals_Grid">
              {latestProducts?.length > 0 ? (
                latestProducts?.map((product) => (
                  <HomeCard product={product} />
                ))
              ) : (
                <p>No new arrivals available.</p>
              )}
            </div>
            <Banner props={mainBanner} />
            <ProductGrid />
            <br/>
            <h1>Популярни чифтове</h1> 
            <SliderComponent> 
              {shuffledProducts && shuffledProducts.length > 0 ? (
                shuffledProducts.map((product) => (
                  <HomeCard key={product._id} product={product} />
                ))
              ) : (
                <p>No latest products available</p>
              )}
            </SliderComponent>
            <br/>
            <h1>Най-известни модели</h1>
            <SliderComponent>
              {shuffleArray(sliderData || []).map((item, index) => (
                <div key={index} className="slider-item">
                  <Link to={`/shop/${item.url}`} rel="noopener noreferrer">
                    <img src={item.image} alt={item.name} />
                    <h3>{item.name}</h3>
                  </Link>
                </div>
              ))}
            </SliderComponent>
          </div>
        </>
      )}
    </>
  );
}

export default Home;