import React from "react";
import notFound from "../../../Image/Loader-svg/not_found.png";
import "./Loader.css";

const NotFound = () => (
  <div className="cricket-ball-loader">
    <img src={notFound} />
  </div>
);

export default NotFound;
