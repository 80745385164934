import React, { useEffect } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveAddress, saveShippingInfo, toggleHomeDelivery } from "../../actions/cartAction";
import MetaData from "../layouts/MataData/MataData";
import CheckoutSteps from "./CheckoutSteps ";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

import {
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OfficeDropdown from "./OfficeDropdown";


const useStyles = makeStyles((theme) => ({
  shippingRoot: {
    width: "60%",
    margin: "auto",
  },
  heading: {
    marginBottom: theme.spacing(2),
    alignSelf: "flex-start",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
    backgroundColor: "#000000",
    color: "#FFFFFF",
    height: "4rem",
    "&:hover": {
      backgroundColor: "#9caccc",
      color: "#FFFFFF",
    },
  },
  outlinedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000000",
      },
      "&:hover fieldset": {
        borderColor: "#000000", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000000", 
      },
    },
    "& .MuiInputBase-input": {
      color: "#000000", 
    },
    "& .MuiInputLabel-root": {
      color: "#000000", 
    },
  },


}));

const Shipping = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shippingInfo, homeDelivery, ...cart } = useSelector((state) => state.cart);

  const classes = useStyles();
  const [address, setAddress] = React.useState(shippingInfo.address);
  const [firstName, setFirstName] = React.useState(shippingInfo.firstName);
  const [lastName, setLastName] = React.useState(shippingInfo.lastName);
  const [city, setCity] = React.useState(shippingInfo.city);
  const [pinCode, setPinCode] = React.useState(shippingInfo.pinCode);
  const [state, setState] = React.useState(shippingInfo.state);
  const [country, setCountry] = React.useState(shippingInfo.country);
  const [phoneNo, setPhone] = React.useState(shippingInfo.phoneNo || "");
  const [email, setEmail] = React.useState(shippingInfo.email);
  // const [saveAddress, setSaveAddress] = React.useState(false);
  const [sameBillingDelivery, setSameBillingDelivery] = React.useState(false);
const [isValidEmail, setIsValidEmail] = React.useState(true);
const [isPhoneNoValid, setIsPhoneNoValid] = React.useState(true);

  useEffect(() => {
    // window.fbq('trackCustom', 'InitializedCheckout', {
    //   items_quantity: cart?.cartItems?.length,
    //   items_overall_price: cart?.cartItems?.reduce((acc, item) => acc + item.price * item.quantity, 0),
    //   items_models: cart?.cartItems?.map(item => item.name).join(', '),
    //   items_sizes: cart?.cartItems?.map(item => item.size).toString()
    // });

  }, [])

  const handleDeliveryMethod = (event) => {
    dispatch(toggleHomeDelivery())
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handlePincodeChange = (event) => {
    const input = event.target.value;
  
    // Check if the input is a valid number (using a regex that matches digits only)
    if (/^\d*$/.test(input)) {
      setPinCode(Number(input));
    }
  };

  const handleStateChange = (event) => {

    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handlePhoneChange = (event) => {
    const newPhoneNo = event.target.value;
    setPhone(newPhoneNo);
    setIsPhoneNoValid(newPhoneNo !== "" && newPhoneNo?.length === 10);
  }; 

 const handleEmailChange = (event) => {
   const newEmail = event.target.value;
   
   setEmail(newEmail);
   setIsValidEmail(
     newEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)
   );
 };

  // const handleSaveAddressChange = (event) => {
  //   setSaveAddress(event.target.checked);
  // };

  const handleSetOffice = (office) => {
    setAddress(`${office.Column2} - ${office.Column6}`);
  }

  // const handleSameBillingDeliveryChange = (event) => {
  //   setSameBillingDelivery(event.target.checked);
  // };

 const handleSubmit = (event) => {
   event.preventDefault();

   if (
     email === "" ||
     firstName === "" ||
     lastName === "" ||
     address === "" ||
     city === "" ||
     state === "" ||
     country === "" ||
     pinCode === "" ||
     phoneNo === ""
   ) {
     alert.error("Моля попълнете всички полета!");
     return;
   }

   if (phoneNo && phoneNo?.length !== 10) {
     alert.error("Моля въведете валиден телефонен номер.");
     return;
   }

   if(String(pinCode).length !== 4){
      alert.error("Моля въведете валиден пощенски код.");
      return
   }

   dispatch(
     saveShippingInfo({
       address,
       city,
       state,
       country,
       pinCode,
       phoneNo,
       email,
       firstName,
       lastName,
     })
   );
   history.push("/process/payment");
 };

  const handleSaveAddress = () => {
    dispatch(saveAddress(address))
  }

  return (
    <>
      <div className="shippingPage">
        <MetaData title={"Shipping Info"} />
        <CheckoutSteps saveAddress={handleSaveAddress} activeStep={1} />

        <div className="shippingPage__container">
          <div className="shippingPage__container__left">
            <div className={classes.shippingRoot}>
              <form onSubmit={handleSubmit}>
                <Typography variant="h6" className={classes.heading}>
                  Доставка
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Първо Име"
                      variant="outlined"
                      fullWidth
                      value={firstName}
                      onChange={handleFirstNameChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Фамилия"
                      variant="outlined"
                      fullWidth
                      value={lastName}
                      onChange={handleLastNameChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Typography style={{ textAlign: 'center' }} variant="h6" className={classes.heading}>
                  Метод за доставка
                  </Typography>
                  <Grid container className={classes.rememberMeContainer}>
                    <div className="delivery order_Summary_Item">
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          onChange={handleDeliveryMethod}
                          checked={homeDelivery}
                          value="address"
                          label="До адрес (с фирма Еконт)"
                        />
                      </Grid>
                    </div>
                    <div className="delivery order_Summary_Item">
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          onChange={handleDeliveryMethod}
                          checked={!homeDelivery}
                          value="office"
                          label="До офис на Еконт"
                        />
                      </Grid>
                    </div>
                  </Grid>
                    <Grid item xs={12}>
                    <TextField
                      label="Адрес"
                      variant="outlined"
                      fullWidth
                      value={address}
                      onChange={handleAddressChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Град"
                      variant="outlined"
                      fullWidth
                      value={city}
                      onChange={handleCityChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Пощенски код"
                      variant="outlined"
                      fullWidth
                      value={pinCode}
                      onChange={handlePincodeChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Област"
                      variant="outlined"
                      fullWidth
                      value={state}
                      onChange={handleStateChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Държава"
                      variant="outlined"
                      fullWidth
                      value={country}
                      onChange={handleCountryChange}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Телефонен номер"
                      variant="outlined"
                      fullWidth
                      value={phoneNo}
                      onChange={handlePhoneChange}
                      className={classes.outlinedInput}
                      error={!isPhoneNoValid && phoneNo !== ""}
                      helperText={
                        !isPhoneNoValid &&
                        phoneNo &&
                        "Моля въведете валиден телефон."
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Имейл"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={handleEmailChange}
                      className={classes.outlinedInput}
                      error={!isValidEmail && email !== ""}
                      helperText={
                        !isValidEmail &&
                        email &&
                        "Моля въведете валиден имейл."
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveAddress}
                          style={{ color: "#000000" }}
                          onChange={handleSaveAddressChange}
                        />
                      }
                      label="Save Address to Address Book"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameBillingDelivery}
                          style={{ color: "#000000" }}
                          onChange={handleSameBillingDeliveryChange}
                        />
                      }
                      label="My billing and delivery information are the same."
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                     
                    >
                      Продължи към плащане
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
