/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './Product.css';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import useActive from '../hook/useActive';
import { clearErrors, getProductDetails } from '../../actions/productAction';
import { PRODUCT_DETAILS_RESET } from '../../constants/productsConstatns';
import { calculateDiscount, dispalyMoney, generateDiscountedPrice } from '../DisplayMoney/DisplayMoney';
import { addItemToCart } from '../../actions/cartAction';
import { Button } from '@mui/material';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FaTruckFast } from "react-icons/fa6";
import { LuPackageSearch } from "react-icons/lu";
import { GiWallet } from "react-icons/gi";
import ImageSlider from './ImageSlider';

const Product = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [selectedSize, setSelectedSize] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [previewImg, setPreviewImg] = useState(null);
  const { handleActive, activeClass } = useActive(0);
  const [showSlider, setShowSlider] = useState(false);
  const { product, loading, error , success  } = useSelector(
    (state) => state.productDetails
  );  
  const { cartItems  } = useSelector(
    (state) => state.cart
  );  

  useEffect(() => {
    dispatch(getProductDetails(match.params.id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success && product) {
      setPreviewImg(product.images?.[0].url);
      setSelectedSize(Object.keys(product.Stock || [])[0]);
      handleActive(0);
      dispatch({ type: PRODUCT_DETAILS_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 
  [dispatch, error, alert, success, match.params.id]); 

  useEffect(() => {
    setPreviewImg(product.images?.[0].url);  
  }, [product.images])

    // handling Add-to-cart
    const handleAddItem = () => {
      window.fbq('track', 'AddToCart', {
        content_ids: [product._id],
        content_type: 'product'
      });
      dispatch(addItemToCart(match.params.id, selectedSize, true));
      alert.success("Продуктът е добавен в количката.");
    };

    // handling Preview image
    const handlePreviewImg = (images, i) => {
      setPreviewImg(images[i].url);
      handleActive(i);
    };

    function increaseQuantityHandler() {
      if (product?.Stock[selectedSize] <= quantity) {
        return;
      }

      setQuantity((prv) => prv + 1);
    }

    function deceraseQuantityHandler() {
      if (quantity <= 1) {
          return;
      }
      setQuantity((prv) => prv - 1);
    }

    const handleSizeChange = (e) => {
      setSelectedSize(e.target.value);
    };

    const getNonZeroStockCount = (stock) => {
        return Object.values(stock).reduce((count, qty) => count + (qty > 0 ? 1 : 0), 0);
    };  

    const toggleModal = () => {
      setShowSlider((prev) => !prev);
    }
        
  return (
    <div className="product-container">
      <div className="image-gallery">
        { showSlider && <ImageSlider onClose={toggleModal} images={product?.images} /> }
        <div onClick={toggleModal} className="main-image">
          <img src={previewImg} alt="Main" />
        </div>
        <div className="thumbnails">
          {product?.images?.map((image, index) => (
            <img
              src={image.url}
              alt={`Thumbnail ${index + 1}`}
              key={index}
              onClick={() => setPreviewImg(image.url)}
            />
          ))}
        </div>
      </div>
      <div className="product-details">
        <h1>{product?.name}</h1>
        <p style={{ color: '#888', fontWeight: 'normal'}}>{product?.category}</p>
        <p>{product?.info && product.info}</p>
        <h3 className="price">{dispalyMoney(product?.price)}</h3>
        <div className="badge">
            {getNonZeroStockCount(product?.Stock || {}) > 0 ? (
                <span className="instock">
                    <DoneIcon fontSize="50" /> В наличност
                </span>
            ) : (
                <span className="outofstock">
                <CloseIcon fontSize="100" />
                Изчерпано
                </span>
            )}
        </div>
        <div className="product-description">
          <p> 
            {product?.description}
          </p>
          <div className="size-chart">
            <div>
                <h3>Избери размер</h3>
                <div className="sizes">
                  {Object.keys(product?.Stock || {}).map((size, index) => (
                      <Button className={`${selectedSize === size ? "size-button-selected" : "size-button"}`} value={size} onClick={handleSizeChange} key={index}>
                          {size}
                      </Button>
                  ))}
                </div>
            </div>
            <div className="product-buttons">
                <Button
                    variant="contained"
                    className="prod_details_addtocart_btn"
                    onClick={handleAddItem}
                    disabled={product.Stock && product?.Stock[selectedSize] && product.Stock[selectedSize] > 0 ? false : true}
                    >
                    {product.Stock && product?.Stock[selectedSize] && product.Stock[selectedSize] > 0 ? "Добави в количка" : "Изчерпано"}
                </Button>
            </div>
          </div>
        </div>
        <div className="shipping-info">
          <p><FaTruckFast size={20} />{" "}Бърза доставка</p>
          <p><LuPackageSearch size={20} />{" "}Ще видите нашите опции за доставка при финализиране на поръчката</p>
          <p><GiWallet size={20} />{" "}Наложен платеж</p>
          <Button style={{ marginTop: '15px'}} className='see_more_btn' href="/shop">Виж други продукти</Button>
        </div>
      </div>
    </div>
);
};

export default Product;
